import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './ContactUs.module.css';

const ContactUs = () => {
  const { t } = useTranslation();

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          version
        }
      }
    }
  `);

  return (
    <section>
      <div className={styles.container}>
        <h6>
          <a className="underline" href="mailto:info@careersocius.com">
            {t('contactUs.contactUs')}
          </a>{' '}
          {t('contactUs.contactUsDetails')}
        </h6>
        <h6>
          {t('contactUs.visit')}{' '}
          <a
            className="underline"
            href="https://www.careersocius.com/"
            rel="noreferrer"
            target="_blank"
          >
            {t('contactUs.website')}
          </a>{' '}
          {t('contactUs.action')}
        </h6>
      </div>
      <div className="my-4 text-center opacity-50 text-sm flex flex-col items-center justify-center">
        {t('aboutCheck.webname')}
        <span className="mt-2 font-medium">v{site.siteMetadata.version}</span>
      </div>
    </section>
  );
};

export default memo(ContactUs);
