import { isFunction } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './Input.module.css';
import useSelector from '../../hooks/useAppSelector';
import useDispatch from '../../hooks/useAppDispatch';
import {
  selectResumeItem,
  resumeOnInput,
} from '../../redux/slices/resumeSlice';

const ColorInput = ({
  name,
  path,
  label,
  error,
  value,
  onBlur,
  touched,
  onChange,
  onClick,
  className,
  placeholder,
}) => {
  const [uuid, setUuid] = useState(null);
  const stateValue = useSelector(selectResumeItem(path, ''));
  const dispatch = useDispatch();

  useEffect(() => {
    setUuid(uuidv4());
  }, []);

  value = path ? stateValue : value;
  onChange = isFunction(onChange)
    ? onChange
    : (e) => {
        dispatch(
          resumeOnInput({
            path,
            value: e.target.value,
          }),
        );
      };

  return (
    <div className={className}>
      <label htmlFor={uuid}>
        <span className="flex flex-row gap-1">{label} </span>

        <div className="relative grid items-center">
          <div className={styles.circle} style={{ backgroundColor: value }} />

          <input
            id={uuid}
            name={name}
            type="text"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            placeholder={placeholder}
          />
        </div>

        {error && touched && <p>{error}</p>}
      </label>
    </div>
  );
};

export default memo(ColorInput);
