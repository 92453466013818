import { Formik } from 'formik';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Input from '../../Input';
import PhotoUpload from '../../PhotoUpload';
import Heading from '../../Heading';
import { getFieldPropsNoSubmit } from '../../../../utils';
import styles from './Profile.module.css';

const Profile = ({ id }) => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    heading: Yup.string(),
    firstName: Yup.string().required(t('shared.forms.validation.required')),
    lastName: Yup.string().required(t('shared.forms.validation.required')),
    subtitle: Yup.string(),
  });

  return (
    <section>
      <Formik validateOnBlur validationSchema={schema}>
        {(formik) => (
          <div>
            <div className={styles.form}>
              <Input
                name="heading"
                label={t('builder.sections.heading')}
                path={`${id}.heading`}
              />
            </div>
            <div className={`grid grid-cols-2 gap-6 ${styles.form}`}>
              <Input
                name="firstName"
                label={t('builder.profile.firstName')}
                path="profile.firstName"
                {...getFieldPropsNoSubmit(formik, schema, 'firstName')}
              />
              <Input
                name="lastName"
                label={t('builder.profile.lastName')}
                path="profile.lastName"
                {...getFieldPropsNoSubmit(formik, schema, 'lastName')}
              />
            </div>
            <div className={styles.form}>
              <Input
                name="subtitle"
                label={t('shared.forms.subtitle')}
                path="profile.subtitle"
                {...getFieldPropsNoSubmit(formik, schema, 'subtitle')}
              />
            </div>
            <PhotoUpload />
          </div>
        )}
      </Formik>
    </section>
  );
};

export function ProfileHeading({ id }) {
  return <Heading id={id} />;
}

export default memo(Profile);
