import React, { memo, useContext } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import styles from './VersionPreviewDialog.module.css';
import useResume from '../../../hooks/useResume';
import ModalContext from '../../../contexts/ModalContext';

const VersionPreviewDialog = ({ resumeObject }) => {
  const { t } = useTranslation();
  const { resumeVersionId } = useResume();
  const handleDate = (createdAt) => {
    return dayjs(parseInt(createdAt, 10)).format('DD/MM/YYYY');
  };
  const date = handleDate(resumeObject.createdAt);
  const { emitter, events } = useContext(ModalContext);
  const handleRevert = () => emitter.emit(events.REVERT_VERSION_MODAL);
  const isCurrentVersion = resumeVersionId === resumeObject.versionId;
  const versionHeading = isCurrentVersion
    ? `${t('versionPreview.currentVersion')} ${resumeObject.versionId}, ${t(
        'versionPreview.createdAt',
      )} ${date}.`
    : `${t('versionPreview.previousVersion')} ${resumeObject.versionId}, ${t(
        'versionPreview.createdAt',
      )} ${date}.`;

  return (
    <>
      <div className={styles.container}>
        <Alert severity="info">
          <AlertTitle>
            <b>{versionHeading}</b>
          </AlertTitle>
          {!isCurrentVersion && (
            <Link onClick={handleRevert}>{t('versionPreview.revert')}</Link>
          )}
        </Alert>
      </div>
    </>
  );
};

export default memo(VersionPreviewDialog);
