import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import useResume from '../../../hooks/useResume';
import Pikachu from '../templates/Pikachu';
import ZetyBanking from '../templates/ZetyBanking';
import WallStreet from '../templates/WallStreet';
import ZetyManagement from '../templates/ZetyManagement';
import ZetyDataAnalyst from '../templates/ZetyDataAnalyst';
import styles from './Artboard.module.css';

import ROOT_URL from '../../../constants/url';

const Artboard = () => {
  const { resume, previewResume } = useResume();
  const finalResume = previewResume == null ? resume : previewResume;
  const { id, name, metadata } = finalResume;
  const { template } = metadata;

  return (
    <>
      <Helmet>
        <title>{name} | CareerSocius Resume Builder</title>
        <link rel="canonical" href={`${ROOT_URL}/app/builder/${id}`} />
      </Helmet>

      <div
        className={styles.container}
        style={{
          backgroundColor:
            resume.metadata.template !== 'zetymanagement'
              ? resume.metadata.colors.background
              : 'white',
          paddingTop:
            resume.metadata.template === 'zetymanagement' ||
            resume.metadata.template === 'zetydataanalyst'
              ? '0'
              : resume.metadata.margins.tbMargins,
          paddingBottom:
            resume.metadata.template === 'zetymanagement' ||
            resume.metadata.template === 'zetydataanalyst'
              ? '0'
              : resume.metadata.margins.tbMargins,
        }}
      >
        {template === 'pikachu' && <Pikachu data={finalResume} />}
        {template === 'zetybanking' && <ZetyBanking data={finalResume} />}
        {template === 'wallStreet' && <WallStreet data={finalResume} />}
        {template === 'zetymanagement' && <ZetyManagement data={finalResume} />}
        {template === 'zetydataanalyst' && (
          <ZetyDataAnalyst data={finalResume} />
        )}
      </div>
    </>
  );
};

export default memo(Artboard);
