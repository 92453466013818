import React, { memo } from 'react';
import MarginSlider from '../../MarginSlider';
import sliderSections from '../../../../constants/marginOptions';

const Margins = () => {
  return (
    <section>
      {Object.entries(sliderSections).map(([key, value]) => {
        const path = `metadata.margins.${key}`;
        return (
          <MarginSlider
            title={value.name}
            path={path}
            id={value.id}
            key={key}
          />
        );
      })}
    </section>
  );
};

export default memo(Margins);
