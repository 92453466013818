import React, { Fragment, memo } from 'react';
import { Element } from 'react-scroll';
import { exportSections } from '../../../../constants/leftTabBarSections';
import Actions from '../sections/Actions';

const getComponent = (id) => {
  switch (id) {
    case 'actions':
      return Actions;
    default:
      throw new Error();
  }
};

const ExportSection = ({ id, event }) => {
  const Component = getComponent(id);

  return (
    <Fragment key={id}>
      <Element name={id}>
        <Component id={id} event={event} />
      </Element>
      <hr />
    </Fragment>
  );
};

const Export = () => {
  return (
    <div className="flex">
      <div id="Export">{exportSections.map(ExportSection)}</div>
    </div>
  );
};

export default memo(Export);
