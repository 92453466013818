import React, { Fragment, memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { Element } from 'react-scroll';
import { List, ListItem } from '@material-ui/core';
import dayjs from 'dayjs';
import useDispatch from '../../../../hooks/useAppDispatch';
import { versionsSections } from '../../../../constants/leftTabBarSections';
import useResume from '../../../../hooks/useResume';
import Heading from '../../Heading';
import { resumePreviewVersion } from '../../../../redux/slices/resumeSlice';

const Versions = ({ id }) => {
  const [versions, setVersions] = useState([]);
  const {
    resume,
    getAllResumeVersions,
    resumeVersionId,
    resumeIsUpdating,
  } = useResume();
  const [selectedVersion, setSelectedVersion] = useState(resumeVersionId);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (resumeIsUpdating) {
      return;
    }
    (async () => {
      const data = await getAllResumeVersions(resume.id);
      const versionArr = [];
      for (const [key, value] of Object.entries(data.versions)) {
        versionArr.push({
          versionId: `${key}`,
          updatedAt: `${value.updatedAt ? value.updatedAt : value.createdAt}`,
        });
      }
      versionArr.sort((a, b) => {
        return b.updatedAt - a.updatedAt;
      });
      setVersions(versionArr);
      setSelectedVersion(resumeVersionId);
      if (!data) {
        navigate('/');
        toast.error(t('builder.toasts.doesNotExist'));
        return null;
      }
    })();
  }, [resume, resumeVersionId, resumeIsUpdating]);

  const handleDate = (updatedAt) => {
    return dayjs(parseInt(updatedAt, 10)).format('DD/MM/YYYY HH:mm:ss');
  };

  const handleResumePreviewVersion = (event, version) => {
    setSelectedVersion(version);
    dispatch(
      resumePreviewVersion({
        resumeId: resume.id,
        versionId: version.versionId,
      }),
    );
  };

  return (
    <div>
      <Heading id={id} />
      <div id="Versions">
        <List dense disablePadding>
          {versions.map((version, index) => (
            <ListItem
              selected={selectedVersion === version}
              onClick={(event) => handleResumePreviewVersion(event, version)}
            >
              <div className="grid grid-cols-2 p-6">
                <div className="col-span-1 text-lg text-black items-center font-bold">
                  Version updated at {handleDate(version.updatedAt)}
                </div>
                <p className="col-span-2">
                  {index === 0 ? '(Latest Version)' : ''}
                </p>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

const getComponent = (id) => {
  switch (id) {
    case 'versions':
      return Versions;
    default:
      throw new Error();
  }
};

const VersionsSection = ({ id, event }) => {
  const Component = getComponent(id);

  return (
    <Fragment key={id}>
      <Element name={id}>
        <Component id={id} event={event} />
      </Element>
      <hr />
    </Fragment>
  );
};

const VersionHistory = () => {
  return (
    <div className="flex">
      <div id="Versions">{versionsSections.map(VersionsSection)}</div>
    </div>
  );
};

export default memo(VersionHistory);
