import { Formik } from 'formik';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import TipList from '../../../shared/TipList';
import Input from '../../Input';
import { getFieldPropsNoSubmit } from '../../../../utils';
import styles from './Profile.module.css';
import Heading from '../../Heading';

const Objective = ({ id }) => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    heading: Yup.string(),
    textarea: Yup.string().required(t('shared.forms.validation.required')),
  });

  const tips = t('builder.tooltips.objectiveTips', {
    returnObjects: true,
  });

  return (
    <section>
      <Formik validateOnBlur validationSchema={schema}>
        {(formik) => (
          <div>
            <div className={styles.form}>
              <Input
                name="heading"
                label={t('builder.sections.heading')}
                path={`${id}.heading`}
                {...getFieldPropsNoSubmit(formik, schema, 'heading')}
              />
            </div>

            <div className={styles.form}>
              <Input
                type="textarea"
                label={t('shared.forms.summary')}
                path={`${id}.body`}
                tooltip={<TipList tiplist={tips} />}
                {...getFieldPropsNoSubmit(formik, schema, 'textarea')}
              />
            </div>
          </div>
        )}
      </Formik>
    </section>
  );
};

export function ObjectiveHeading({ id }) {
  return <Heading id={id} />;
}

export default memo(Objective);
