import React, { Fragment, memo } from 'react';
import { Element } from 'react-scroll';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { MdExpandMore } from 'react-icons/md/';
import { designSections } from '../../../../constants/leftTabBarSections';
import styles from '../LeftTabBar.module.css';
import Colors from '../sections/Colors';
import Fonts from '../sections/Fonts';
import Layout from '../sections/Layout';
import Templates from '../sections/Templates';
import Margins from '../sections/Margins';
import Heading from '../../Heading';

const getComponent = (id) => {
  switch (id) {
    case 'templates':
      return Templates;
    case 'layout':
      return Layout;
    case 'margins':
      return Margins;
    case 'colors':
      return Colors;
    case 'fonts':
      return Fonts;
    default:
      throw new Error();
  }
};

const DesignSection = ({ id, event }) => {
  const Component = getComponent(id);

  return (
    <Fragment key={id}>
      <Element name={id} className="w-full">
        <Component id={id} event={event} />
      </Element>
    </Fragment>
  );
};

const Design = () => {
  const [expanded, setExpanded] = React.useState('');
  const handleChange = (section) => {
    setExpanded(expanded === section ? '' : section);
  };

  return (
    <div className="flex">
      <div id="Design" className={styles.accordions}>
        {designSections.map((section) => (
          <Accordion
            expanded={expanded === section.id}
            onChange={() => handleChange(section.id)}
            key={`design_accordion_${section.id}`}
          >
            <AccordionSummary expandIcon={<MdExpandMore />}>
              <Heading id={section.id} />
            </AccordionSummary>
            <AccordionDetails>
              <DesignSection id={section.id} event={section.event} />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default memo(Design);
