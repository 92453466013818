import cx from 'classnames';
import React, { memo } from 'react';
import useSelector from '../../../../hooks/useAppSelector';
import fontOptions from '../../../../constants/fontOptions';
import { handleKeyUp } from '../../../../utils';
import styles from './Fonts.module.css';
import {
  resumeOnInput,
  selectResumeItem,
} from '../../../../redux/slices/resumeSlice';
import useDispatch from '../../../../hooks/useAppDispatch';

const Fonts = () => {
  const dispatch = useDispatch();
  const font = useSelector(selectResumeItem('metadata.font'));

  const handleClick = (value) => {
    dispatch(
      resumeOnInput({
        path: 'metadata.font',
        value,
      }),
    );
  };

  return (
    <section>
      <div className="grid grid-cols-2 gap-8">
        {fontOptions.map((x) => (
          <div
            key={x}
            tabIndex="0"
            role="button"
            style={{ fontFamily: x }}
            className={cx(styles.font, { [styles.selected]: font === x })}
            onKeyUp={(e) => handleKeyUp(e, () => handleClick(x))}
            onClick={() => handleClick(x)}
          >
            {x}
          </div>
        ))}
      </div>
    </section>
  );
};

export default memo(Fonts);
