import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, FormControlLabel } from '@material-ui/core';
import useDispatch from '../../../../hooks/useAppDispatch';
import useResume from '../../../../hooks/useResume';
import {
  resumeLoadDemoData,
  resumeResetData,
  resumeSetIsPublic,
} from '../../../../redux/slices/resumeSlice';
import Button from '../../../shared/Button';
import Heading from '../../Heading';
import Input from '../../Input';
import styles from './Actions.module.css';

import ROOT_URL from '../../../../constants/url';
import hasWindow from '../../../../utils/hasWindow';

const Actions = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showOptions, setShowOptions] = useState(false);
  const [resetText, setResetText] = useState(
    t('builder.actions.resetEverything.button'),
  );

  const { resume, resumeIsPublic } = useResume();
  const [isPublic, setIsPublic] = useState(resumeIsPublic);

  const toggleChecked = () => {
    setIsPublic((prev) => !prev);
  };

  useEffect(() => {
    dispatch(
      resumeSetIsPublic({
        resumeId: resume.id,
        isPublic,
      }),
    );
  }, [dispatch, isPublic]);

  const getSharableUrl = () => {
    const resumeId = resume.id;
    return `${ROOT_URL}/r/${resumeId}`;
  };

  const handleOpenLink = () => {
    if (hasWindow()) {
      window.open(getSharableUrl());
    }
  };

  const handleLoadDemo = () => {
    dispatch(resumeLoadDemoData());
    handleShowOptions();
  };

  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleReset = () => {
    if (resetText === t('builder.actions.resetEverything.button')) {
      setResetText(t('shared.buttons.confirmation'));
      return;
    }

    setResetText(t('builder.actions.resetEverything.button'));
    dispatch(resumeResetData());
  };

  return (
    <section>
      <Heading id={id} />

      <div className={styles.container}>
        <h5>{t('builder.actions.exportYourResume.heading')}</h5>

        <p className="leading-loose">
          {t('builder.actions.exportYourResume.text1')}
          <b>{t('builder.actions.exportYourResume.command')}</b>
          {t('builder.actions.exportYourResume.text2')}
        </p>

        <div>
          <Input
            type="action"
            value={getSharableUrl()}
            onClick={handleOpenLink}
          />
        </div>
      </div>

      <div className={styles.container}>
        <h5>{t('builder.actions.privacySharing.heading')}</h5>

        <div className="flex justify-center items-center">
          <FormControlLabel
            control={<Switch checked={!isPublic} onChange={toggleChecked} />}
            label="Private Mode"
            labelPlacement="start"
          />
        </div>

        <p className="leading-loose">
          {t('builder.actions.privacySharing.text')}
        </p>

        <div>
          <Input
            type="action"
            value={getSharableUrl()}
            onClick={handleOpenLink}
          />
        </div>
      </div>

      <div className={styles.container}>
        <h5>{t('builder.actions.loadDemoData.button')}</h5>

        <p className="leading-loose">
          {t('builder.actions.loadDemoData.text')}
        </p>

        {showOptions ? (
          <div className="mt-4 flex flex-col">
            <div>
              <h6 className="italic font-bold text-lg mb-4">
                {t('builder.actions.loadDemoData.prompt')}
              </h6>
            </div>
            <div className="flex flex-row">
              <Button onClick={handleLoadDemo} className="mr-4">
                {t('builder.actions.loadDemoData.optionYes')}
              </Button>
              <Button onClick={handleShowOptions}>
                {t('builder.actions.loadDemoData.optionNo')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="mt-4 flex">
            <Button onClick={handleShowOptions}>
              {t('builder.actions.loadDemoData.button')}
            </Button>
          </div>
        )}
      </div>

      <div className={styles.container}>
        <h5>{t('builder.actions.resetEverything.button')}</h5>

        <p className="leading-loose">
          {t('builder.actions.resetEverything.text')}
        </p>

        <div className="mt-4 flex">
          <Button onClick={handleReset}>{resetText}</Button>
        </div>
      </div>
    </section>
  );
};

export default memo(Actions);
