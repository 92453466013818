import React from 'react';
import { Slider, Input, Grid } from '@material-ui/core';
import { thumb, marginSlider } from './MarginSlider.module.css';
import useSelector from '../../hooks/useAppSelector';
import {
  resumeOnInput,
  selectResumeItem,
} from '../../redux/slices/resumeSlice';
import useDispatch from '../../hooks/useAppDispatch';
import sliderSections from '../../constants/marginOptions';

type MarginSliderProps = { title: string; path: string; id: string };

const MarginSlider = ({ title, path, id }: MarginSliderProps) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(
    useSelector(selectResumeItem(`${path}`, [])),
  );
  const sliderSection = sliderSections[id].slider;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      event.target.value === ''
        ? ''
        : Math.floor(Number(event.target.value) / sliderSection.step) *
            sliderSection.step,
    );
    dispatch(
      resumeOnInput({
        path: `${path}`,
        value: event.target.value,
      }),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setValue(Number(newValue));
    dispatch(
      resumeOnInput({
        path: `${path}`,
        value: newValue,
      }),
    );
  };

  const handleBlur = () => {
    if (value < sliderSection.min) {
      setValue(sliderSection.min);
    } else if (value > sliderSection.max) {
      setValue(sliderSection.max);
    }
  };

  return (
    <div className={marginSlider}>
      <div>{title}</div>
      <Grid container spacing={2} className={marginSlider}>
        <Grid item xs={9}>
          <Slider
            value={Number(value)}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            min={Number(sliderSection.min)}
            max={Number(sliderSection.max)}
            step={Number(sliderSection.step)}
            marks={sliderSection.marks}
            className={`${thumb}`}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            value={value}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: Number(sliderSection.step),
              min: Number(sliderSection.min),
              max: Number(sliderSection.max),
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MarginSlider;
