import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import { handleKeyUp } from '../../../../utils';
import ColorInput from '../../ColorInput';
import useDispatch from '../../../../hooks/useAppDispatch';
import useSelector from '../../../../hooks/useAppSelector';

import {
  resumeOnInput,
  selectResumeItem,
} from '../../../../redux/slices/resumeSlice';

const Colors = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const colors = useSelector(selectResumeItem('metadata.colors'));
  const { primary, text, background } = { ...colors };
  const [colourPath, setColourPath] = useState('metadata.colors.primary');
  const [colour, setColour] = useState(primary);
  const [primaryColour, setPrimaryColour] = useState(primary);
  const [textColour, setTextColour] = useState(text);
  const [backgroundColour, setBackgroundColour] = useState(background);

  const handleClick = (value) => {
    dispatch(resumeOnInput({ path: colourPath, value }));
  };

  const handlePath = (colourPathText) => {
    setColourPath(colourPathText);
    switch (colourPathText) {
      case 'metadata.colors.primary':
        setColour(primaryColour);
        break;
      case 'metadata.colors.text':
        setColour(textColour);
        break;
      case 'metadata.colors.background':
        setColour(backgroundColour);
        break;
      default:
        setColour(primaryColour);
    }
  };

  const handleSwitch = (hex) => {
    switch (colourPath) {
      case 'metadata.colors.primary':
        setPrimaryColour(hex);
        break;
      case 'metadata.colors.text':
        setTextColour(hex);
        break;
      case 'metadata.colors.background':
        setBackgroundColour(hex);
        break;
      default:
        setPrimaryColour(hex);
    }
  };

  return (
    <section>
      <div className="mb-6 flex justify-center">
        <SketchPicker
          disableAlpha
          color={colour}
          onChange={(color) => {
            handleSwitch(color.hex);
            setColour(color.hex);
          }}
          onKeyUp={(e) => handleKeyUp(e, (color) => handleClick(color.hex))}
          onChangeComplete={(color) => handleClick(color.hex)}
        />
      </div>

      <ColorInput
        type="color"
        name="primary"
        label={t('builder.colors.primary')}
        placeholder="#FF4081"
        onClick={() => handlePath('metadata.colors.primary')}
        path="metadata.colors.primary"
      />

      <ColorInput
        type="color"
        name="text"
        label={t('builder.colors.text')}
        placeholder="#444444"
        onClick={() => handlePath('metadata.colors.text')}
        path="metadata.colors.text"
      />

      <ColorInput
        type="color"
        name="background"
        label={t('builder.colors.background')}
        placeholder="#FFFFFF"
        onClick={() => handlePath('metadata.colors.background')}
        path="metadata.colors.background"
      />
    </section>
  );
};

export default memo(Colors);
