import cx from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React, { memo } from 'react';
import templateOptions from '../../../../constants/templateOptions';
import { handleKeyUp } from '../../../../utils';
import styles from './Templates.module.css';
import useDispatch from '../../../../hooks/useAppDispatch';
import useSelector from '../../../../hooks/useAppSelector';
import {
  selectResumeItem,
  resumeOnInput,
} from '../../../../redux/slices/resumeSlice';

const Templates = () => {
  const dispatch = useDispatch();
  const template = useSelector(selectResumeItem('metadata.template'));

  const previews = useStaticQuery(graphql`
    query {
      wallStreet: file(relativePath: { eq: "templates/wallStreet.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pikachu: file(relativePath: { eq: "templates/pikachu.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zetybanking: file(relativePath: { eq: "templates/zetybanking.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zetymanagement: file(
        relativePath: { eq: "templates/zetymanagement.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      zetydataanalyst: file(
        relativePath: { eq: "templates/zetydataanalyst.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const handleClick = (value) => {
    dispatch(
      resumeOnInput({
        path: 'metadata.template',
        value,
      }),
    );
  };

  return (
    <section>
      <div className="grid grid-cols-2 gap-8">
        {templateOptions.map((x) => (
          <div
            key={x.id}
            tabIndex="0"
            role="button"
            onKeyUp={(e) => handleKeyUp(e, () => handleClick(x.id))}
            onClick={() => handleClick(x.id)}
            className={cx(styles.template, {
              [styles.selected]: template === x.id,
            })}
          >
            <GatsbyImage
              alt={x.name}
              loading="eager"
              className="w-full"
              style={{ height: '230px' }}
              fluid={previews[x.id].childImageSharp.fluid}
            />
            <span>{x.name}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default memo(Templates);
