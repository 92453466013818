import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdInfoOutline } from 'react-icons/md';
import { Tooltip } from '@material-ui/core';
import useSelector from '../../hooks/useAppSelector';
import { selectResumeItem } from '../../redux/slices/resumeSlice';

const Heading = ({ id, tooltip }) => {
  const { t } = useTranslation();
  const heading = useSelector(
    selectResumeItem(`${id}.heading`, t(`builder.sections.${id}`)),
  );

  return (
    <div className="flex flex-row items-center">
      <h2 className="text-2xl focus:outline-none mr-2">{heading}</h2>
      {tooltip && (
        <Tooltip title={tooltip} placement="right" arrow>
          <div>
            <MdInfoOutline size={20} />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default memo(Heading);
