import { Formik } from 'formik';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Input from '../../Input';
import TipList from '../../../shared/TipList';
import Heading from '../../Heading';

import { getFieldPropsNoSubmit } from '../../../../utils';
import styles from './Profile.module.css';

const Contact = () => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    email: Yup.string().required(t('shared.forms.validation.required')),
    phone: Yup.string().required(t('shared.forms.validation.required')),
    addressLine1: Yup.string(),
    addressLine2: Yup.string(),
    city: Yup.string(),
    pincode: Yup.string(),
    website: Yup.string(),
    linkedin: Yup.string(),
  });

  const emailTips = t('builder.tooltips.contactTips.emailTips', {
    returnObjects: true,
  });

  const websiteTips = t('builder.tooltips.contactTips.websiteTips', {
    returnObjects: true,
  });

  return (
    <section>
      <Formik validateOnBlur validationSchema={schema}>
        {(formik) => (
          <div>
            <div className={styles.form}>
              <Input
                name="email"
                label={t('shared.forms.email')}
                path="contact.email"
                tooltip={<TipList tiplist={emailTips} />}
                {...getFieldPropsNoSubmit(formik, schema, 'email')}
              />
            </div>
            <div className={styles.form}>
              <Input
                name="phone"
                label={t('shared.forms.phone')}
                path="contact.phone"
                {...getFieldPropsNoSubmit(formik, schema, 'phone')}
              />
            </div>
            <hr />
            <div className={styles.form}>
              <Input
                name="addressLine1"
                label={t('builder.contact.address.line1')}
                path="contact.address.line1"
                tooltip={t('builder.tooltips.contactTips.addressTips')}
                {...getFieldPropsNoSubmit(formik, schema, 'addressLine1')}
              />
            </div>
            <div className={styles.form}>
              <Input
                name="addressLine2"
                label={t('builder.contact.address.line2')}
                path="contact.address.line2"
                {...getFieldPropsNoSubmit(formik, schema, 'addressLine2')}
              />
            </div>
            <div className={`grid grid-cols-2 gap-6 ${styles.form}`}>
              <Input
                name="city"
                label={t('builder.contact.address.city')}
                path="contact.address.city"
                {...getFieldPropsNoSubmit(formik, schema, 'city')}
              />
              <Input
                name="pincode"
                label={t('builder.contact.address.pincode')}
                path="contact.address.pincode"
                {...getFieldPropsNoSubmit(formik, schema, 'pincode')}
              />
            </div>
            <div className={styles.form}>
              <Input
                name="website"
                type="website"
                label={t('shared.forms.website')}
                path="contact.website"
                placeholder="https://"
                tooltip={<TipList tiplist={websiteTips} />}
                {...getFieldPropsNoSubmit(formik, schema, 'website')}
              />
            </div>
            <div className={styles.form}>
              <Input
                name="linkedin"
                type="website"
                label={t('shared.forms.linkedIn')}
                path="contact.linkedIn"
                placeholder="https://"
                tooltip={t('builder.tooltips.contactTips.linkedInTips')}
                {...getFieldPropsNoSubmit(formik, schema, 'linkedin')}
              />
            </div>
          </div>
        )}
      </Formik>
    </section>
  );
};

export function ContactHeading({ id }) {
  return <Heading id={id} />;
}

export default memo(Contact);
