import React, { memo } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/Button';
import styles from './About.module.css';

const About = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className={styles.container}>
        <h5>{t('aboutCheck.resumecheck')}</h5>

        <p className="leading-loose">
          <b>{t('aboutCheck.cs')}</b> {t('aboutCheck.description')}
        </p>

        <div className="mt-4 flex">
          <a
            href="https://www.careersocius.com/resume-check"
            rel="noreferrer"
            target="_blank"
          >
            <Button icon={FaExternalLinkAlt}>{t('aboutCheck.offering')}</Button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default memo(About);
