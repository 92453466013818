import { navigate } from 'gatsby';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Artboard from '../../components/builder/center/Artboard';
import VersionPreviewDialog from '../../components/builder/center/VersionPreviewDialog';
import LeftTabBar from '../../components/builder/left/LeftTabBar.js';
import LoadingScreen from '../../components/router/LoadingScreen';
import Button from '../../components/shared/Button';
import styles from './builder.module.css';
import useResume from '../../hooks/useResume';
import useDispatch from '../../hooks/useAppDispatch';
import {
  resumeLoadDemoData,
  resumeOnOpen,
} from '../../redux/slices/resumeSlice';

const Builder = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { previewResume, getLatestResume } = useResume();

  const handleLoadDemoData = () => {
    dispatch(resumeLoadDemoData());
  };

  useEffect(() => {
    dispatch(resumeOnOpen({ resumeId: id }));
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const resume = await getLatestResume(id);

      if (!resume) {
        navigate('/app/timeline');
        toast.error(t('builder.toasts.doesNotExist'));
        return null;
      }

      if (resume.createdAt === resume.updatedAt) {
        toast.dark(() => (
          <div className="py-2">
            <p className="leading-loose">{t('builder.toasts.loadDemoData')}</p>

            <Button className="mt-4" onClick={handleLoadDemoData}>
              {t('builder.actions.loadDemoData.button')}
            </Button>
          </div>
        ));
      }

      return setLoading(false);
    })();
  }, [id]);

  return useMemo(() => {
    if (loading) {
      return <LoadingScreen />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <LeftTabBar />
        </div>
        <div className={styles.right}>
          {previewResume != null && (
            <VersionPreviewDialog resumeObject={previewResume} />
          )}
          <Artboard />
        </div>
      </div>
    );
  }, [loading, previewResume]);
};

export default memo(Builder);
