import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../lists/List';
import Input from '../../Input';
import Heading from '../../Heading';
import TipList from '../../../shared/TipList';

const Awards = ({ id, event }) => {
  const path = `${id}.items`;
  const { t } = useTranslation();

  return (
    <section>
      <Input
        name="heading"
        label={t('builder.sections.heading')}
        path={`${id}.heading`}
      />

      <List
        path={path}
        event={event}
        titlePath="title"
        subtitlePath="awarder"
        textPath="summary"
      />
    </section>
  );
};

export function AwardsHeading({ id }) {
  const { t } = useTranslation();
  const tips = t('builder.tooltips.awardsTips.awardsGeneral', {
    returnObjects: true,
  });

  return <Heading id={id} tooltip={<TipList tiplist={tips} />} />;
}

export default memo(Awards);
