import React, { Fragment, memo } from 'react';
import { Element } from 'react-scroll';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Tooltip } from '@material-ui/core';
import { MdExpandMore, MdInfoOutline } from 'react-icons/md/';
import { useTranslation } from 'react-i18next';
import TipList from '../../../shared/TipList';
import { contentSections } from '../../../../constants/leftTabBarSections';
import styles from '../LeftTabBar.module.css';
import Awards, { AwardsHeading } from '../sections/Awards';
import Certifications, {
  CertficationsHeading,
} from '../sections/Certifications';
import Education, { EducationHeading } from '../sections/Education';
import Hobbies, { HobbiesHeading } from '../sections/Hobbies';
import Languages, { LanguagesHeading } from '../sections/Languages';
import Objective, { ObjectiveHeading } from '../sections/Objective';
import Profile, { ProfileHeading } from '../sections/Profile';
import Projects, { ProjectsHeading } from '../sections/Projects';
import Skills, { SkillsHeading } from '../sections/Skills';
import Contact, { ContactHeading } from '../sections/Contact';
import Volunteer, { VolunteerHeading } from '../sections/Volunteer';
import Work, { WorkHeading } from '../sections/Work';

const getComponent = (id) => {
  switch (id) {
    case 'profile':
      return Profile;
    case 'contact':
      return Contact;
    case 'summary':
      return Objective;
    case 'work':
      return Work;
    case 'education':
      return Education;
    case 'skills':
      return Skills;
    case 'projects':
      return Projects;
    case 'certifications':
      return Certifications;
    case 'volunteer':
      return Volunteer;
    case 'hobbies':
      return Hobbies;
    case 'awards':
      return Awards;
    case 'languages':
      return Languages;

    default:
      throw new Error();
  }
};

const getHeadingComponent = (id) => {
  switch (id) {
    case 'profile':
      return ProfileHeading;
    case 'contact':
      return ContactHeading;
    case 'summary':
      return ObjectiveHeading;
    case 'work':
      return WorkHeading;
    case 'education':
      return EducationHeading;
    case 'skills':
      return SkillsHeading;
    case 'projects':
      return ProjectsHeading;
    case 'certifications':
      return CertficationsHeading;
    case 'volunteer':
      return VolunteerHeading;
    case 'hobbies':
      return HobbiesHeading;
    case 'awards':
      return AwardsHeading;
    case 'languages':
      return LanguagesHeading;
    default:
      throw new Error();
  }
};

const ContentSection = ({ id, event }) => {
  const Component = getComponent(id);

  return (
    <Fragment key={id}>
      <Element name={id} className="w-full">
        <Component id={id} event={event} />
      </Element>
    </Fragment>
  );
};

const HeadingSection = ({ id }) => {
  const Heading = getHeadingComponent(id);

  return <Heading id={id} />;
};

const Content = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState('');
  const handleChange = (section) => {
    setExpanded(expanded === section ? '' : section);
  };

  return (
    <div className="flex-col space-y-5">
      <div className="flex flex-row-reverse">
        <Tooltip
          title={
            <TipList
              tiplist={t('builder.tooltips.generalResumeTips', {
                returnObjects: true,
              })}
            />
          }
          placement="right"
          arrow
        >
          <div>
            <MdInfoOutline size={15} />
          </div>
        </Tooltip>
      </div>
      <div id="Content" className={styles.accordions}>
        {contentSections.map((section) => (
          <Accordion
            expanded={expanded === section.id}
            onChange={() => handleChange(section.id)}
            key={`content_accordion_${section.id}`}
          >
            <AccordionSummary expandIcon={<MdExpandMore />}>
              <HeadingSection id={section.id} />
            </AccordionSummary>
            <AccordionDetails>
              <ContentSection id={section.id} event={section.event} />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default memo(Content);
