import React, { memo, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { reorder } from '../../../../utils';
import Button from '../../../shared/Button';
import styles from './Layout.module.css';
import useSelector from '../../../../hooks/useAppSelector';
import useDispatch from '../../../../hooks/useAppDispatch';
import {
  selectResumeItem,
  resumeOnInput,
  resumeResetLayout,
} from '../../../../redux/slices/resumeSlice';
import defaultLayouts from '../../../../data/defaultLayouts';
import { TemplateEnum } from '../../../../types/Template';

const Layout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [resetLayoutText, setResetLayoutText] = useState(
    t('builder.layout.reset'),
  );
  const template = useSelector(selectResumeItem('metadata.template'));
  const blocks = useSelector(
    selectResumeItem(`metadata.layout.${template}`, null),
  );

  const [renderBlocks, setRenderBlocks] = useState(
    blocks || defaultLayouts[template],
  );
  const isSummaryVisible = selectResumeItem('summary.visible', false);
  const isSkillsVisible = selectResumeItem('skills.visible', false);
  const isHobbiesVisible = selectResumeItem('hobbies.visible', false);
  const isLanguagesVisible = selectResumeItem('languages.visible', false);
  const isAwardsVisible = selectResumeItem('awards.visible', false);
  const isCertificationsVisible = selectResumeItem(
    'certifications.visible',
    false,
  );
  const isWorkVisible = selectResumeItem('work.visible', false);
  const isVolunteerVisible = selectResumeItem('volunteer.visible', false);
  const isEducationVisible = selectResumeItem('education.visible', false);
  const isProjectsVisible = selectResumeItem('projects.visible', false);
  const [isBlockVisible, setVisibility] = useState({
    summary: isSummaryVisible,
    skills: isSkillsVisible,
    hobbies: isHobbiesVisible,
    languages: isLanguagesVisible,
    awards: isAwardsVisible,
    certifications: isCertificationsVisible,
    work: isWorkVisible,
    volunteer: isVolunteerVisible,
    education: isEducationVisible,
    projects: isProjectsVisible,
  });

  useEffect(() => {
    if (blocks == null) {
      dispatch(
        resumeOnInput({
          path: `metadata.layout.${template}`,
          value: defaultLayouts[template],
        }),
      );
    }
    setRenderBlocks(blocks);
  }, [blocks]);

  const changeVisibility = (sectionName) => {
    const currVisibilty = isBlockVisible[sectionName];
    toggleVisibility(sectionName);
    dispatch(
      resumeOnInput({
        path: `${sectionName}.visible`,
        value: !currVisibilty,
      }),
    );
    setRenderBlocks((prev) => [...prev]);
  };

  const toggleVisibility = (sectionName) => {
    const newVisibility = isBlockVisible;
    newVisibility[sectionName] = !newVisibility[sectionName];
    setVisibility(newVisibility);
  };

  const onDragEnd = (result) => {
    if (blocks == null) {
      return;
    }
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd !== dInd) return;

    const items = reorder(blocks[sInd], source.index, destination.index);
    const newState = [...blocks];
    newState[sInd] = items;
    dispatch(
      resumeOnInput({
        path: `metadata.layout.${template}`,
        value: newState,
      }),
    );
  };

  const handleResetLayout = () => {
    if (resetLayoutText === t('builder.layout.reset')) {
      setResetLayoutText(t('shared.buttons.confirmation'));
      return;
    }

    dispatch(resumeResetLayout());
    setResetLayoutText(t('builder.layout.reset'));
  };

  const getWallStreetBlockName = (ind) => {
    switch (ind) {
      case 0:
        return `${t('builder.templates.content')}`;
      case 1:
        return `${t('builder.templates.additionalInfo')}`;
      default:
        return `${t('builder.layout.block')} ${ind + 1}`;
    }
  };

  return (
    <section>
      <p className="leading-loose">
        {t('builder.layout.text', { count: renderBlocks.length })}
      </p>

      <div className={`grid gap-8 grid-cols-${renderBlocks.length}`}>
        <DragDropContext onDragEnd={onDragEnd}>
          {renderBlocks.map((el, ind) => (
            <Droppable key={ind} droppableId={`${ind}`} type={`${ind}`}>
              {(dropProvided) => (
                <div
                  ref={dropProvided.innerRef}
                  className={styles.droppable}
                  {...dropProvided.droppableProps}
                >
                  <div className="grid gap-3">
                    <span className="uppercase font-semibold text-xs">
                      {template === TemplateEnum.wallStreet
                        ? getWallStreetBlockName(ind)
                        : `${t('builder.layout.block')} ${ind + 1}`}
                    </span>
                    {el.map((item, index) => (
                      <Draggable
                        key={item}
                        index={index}
                        draggableId={item}
                        type={`${ind}`}
                      >
                        {(dragProvided) => (
                          <div
                            ref={dragProvided.innerRef}
                            className={styles.draggable}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                          >
                            <div className="flex space-x-2 items-center">
                              <div>{t(`builder.sections.${item}`)}</div>
                              {isBlockVisible[item] ? (
                                <MdVisibility
                                  onClick={() => changeVisibility(item)}
                                />
                              ) : (
                                <MdVisibilityOff
                                  onClick={() => changeVisibility(item)}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>

      <div className="flex">
        <Button onClick={handleResetLayout}>{resetLayoutText}</Button>
      </div>
    </section>
  );
};

export default memo(Layout);
