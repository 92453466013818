import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../Input';
import List from '../../lists/List';
import Heading from '../../Heading';
import TipList from '../../../shared/TipList';

const Projects = ({ id, event }) => {
  const path = `${id}.items`;
  const { t } = useTranslation();

  return (
    <section>
      <Input
        name="heading"
        label={t('builder.sections.heading')}
        path={`${id}.heading`}
      />

      <List
        path={path}
        event={event}
        titlePath="title"
        subtitlePath="link"
        textPath="summary"
      />
    </section>
  );
};

export function ProjectsHeading({ id }) {
  const { t } = useTranslation();
  const tips = t('builder.tooltips.projectTips', { returnObjects: true });

  return <Heading id={id} tooltip={<TipList tiplist={tips} />} />;
}

export default memo(Projects);
