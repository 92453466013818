import ModalEvents from './ModalEvents';

export const settingsSections = [
  {
    id: 'settings',
  },
];

export const versionsSections = [
  {
    id: 'versions',
  },
];

export const designSections = [
  {
    id: 'templates',
  },
  {
    id: 'layout',
  },
  {
    id: 'margins',
  },
  {
    id: 'colors',
  },
  {
    id: 'fonts',
  },
];

export const contentSections = [
  {
    id: 'profile',
    fixed: true,
  },
  {
    id: 'contact',
    fixed: true,
  },
  {
    id: 'summary',
  },
  {
    id: 'work',
    event: ModalEvents.WORK_MODAL,
  },
  {
    id: 'education',
    event: ModalEvents.EDUCATION_MODAL,
  },
  {
    id: 'projects',
    event: ModalEvents.PROJECT_MODAL,
  },
  {
    id: 'volunteer',
    event: ModalEvents.VOLUNTEER_MODAL,
  },
  {
    id: 'awards',
    event: ModalEvents.AWARD_MODAL,
  },
  {
    id: 'certifications',
    event: ModalEvents.CERTIFICATION_MODAL,
  },
  {
    id: 'skills',
    event: ModalEvents.SKILL_MODAL,
  },
  {
    id: 'hobbies',
    event: ModalEvents.HOBBY_MODAL,
  },
  {
    id: 'languages',
    event: ModalEvents.LANGUAGE_MODAL,
  },
];

export const exportSections = [
  {
    id: 'actions',
  },
];

export const checkSections = [
  {
    id: 'about',
  },
  {
    id: 'contactUs',
  },
];
