import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import Content from './tabSections/Content';
import Design from './tabSections/Design';
import Export from './tabSections/Export';
import Check from './tabSections/Check';
import VersionHistory from './tabSections/VersionHistory';
import useResume from '../../../hooks/useResume';
import Logo from '../../shared/Logo';
import styles from './LeftTabBar.module.css';
import Avatar from '../../shared/Avatar';
import useDispatch from '../../../hooks/useAppDispatch';
import { setPreviewResume } from '../../../redux/slices/resumeSlice';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const { resumeUpdatedAt } = useResume();

  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (value !== 4) {
        dispatch(setPreviewResume({ previewResume: null }));
      }
    })();
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="flex">
      <div className={`grid grid-cols-1 ${styles.lefttab}`}>
        <div className="flex-col items-center justify-center content-center">
          <Tooltip
            title={t('builder.tooltips.backToDashboard')}
            placement="right"
          >
            <div className="flex justify-center">
              <Link to="/app/timeline">
                <Logo size="40px" />
              </Link>
            </div>
          </Tooltip>
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            className="flex items-center justify-center"
          >
            <Tab label="Content" {...a11yProps('content')} />
            <Tab label="Design" {...a11yProps('design')} />
            {/* <Tab label="Settings" {...a11yProps('settings')} /> */}
            <Tab label="Export" {...a11yProps('export')} />
            <Tab label="Check" {...a11yProps('check')} />
            <Tab label="Versions" {...a11yProps('versions')} />
          </Tabs>

          <div className="flex justify-center">
            <Avatar />
          </div>
          <div className={`flex flex-col items-center ${styles.autosave}`}>
            <b>{t('builder.autosave')}</b>
            <div>{new Date(resumeUpdatedAt).toDateString('en-US')}</div>
            <div>{new Date(resumeUpdatedAt).toLocaleTimeString('en-US')}</div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <TabPanel value={value} index={0}>
          <Content />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Design />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <Settings />
        </TabPanel> */}
        <TabPanel value={value} index={2}>
          <Export />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Check />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <VersionHistory />
        </TabPanel>
      </div>
    </div>
  );
}
