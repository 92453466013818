import React, { Fragment, memo } from 'react';
import { Element } from 'react-scroll';
import { checkSections } from '../../../../constants/leftTabBarSections';
import About from '../sections/About';
import ContactUs from '../sections/ContactUs';

const getComponent = (id) => {
  switch (id) {
    case 'about':
      return About;
    case 'contactUs':
      return ContactUs;
    default:
      throw new Error();
  }
};

const CheckSection = ({ id, event }) => {
  const Component = getComponent(id);

  return (
    <Fragment key={id}>
      <Element name={id}>
        <Component id={id} event={event} />
      </Element>
      <hr />
    </Fragment>
  );
};

const Check = () => {
  return (
    <div>
      <div id="Check">{checkSections.map(CheckSection)}</div>
    </div>
  );
};

export default memo(Check);
