import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../Input';
import List from '../../lists/List';
import Heading from '../../Heading';

const Hobbies = ({ id, event }) => {
  const path = `${id}.items`;
  const { t } = useTranslation();

  return (
    <section>
      <Input
        name="heading"
        label={t('builder.sections.heading')}
        path={`${id}.heading`}
      />

      <List path={path} event={event} titlePath="name" />
    </section>
  );
};

export function HobbiesHeading({ id }) {
  return <Heading id={id} />;
}

export default memo(Hobbies);
