const templateOptions = [
  {
    id: 'wallStreet',
    name: 'CareerSocius General',
  },

  {
    id: 'zetybanking',
    name: 'CareerSocius Basic',
  },

  {
    id: 'pikachu',
    name: 'CareerSocius Dual',
  },

  {
    id: 'zetymanagement',
    name: 'CareerSocius MBA',
  },
  {
    id: 'zetydataanalyst',
    name: 'CareerSocius Astro',
  },
];

export default templateOptions;
