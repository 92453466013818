const sliderSections = {
  fontSize: {
    id: 'fontSize',
    name: 'Font Size',
    slider: {
      min: '9',
      max: '14',
      step: '1',
      marks: [
        {
          value: '9',
          label: '9pt',
        },
        {
          value: '14',
          label: '14pt',
        },
      ],
    },
  },
  lineHeight: {
    id: 'lineHeight',
    name: 'Line Height',
    slider: {
      min: '1.1',
      max: '1.5',
      step: '0.1',
      marks: [
        {
          value: '1.1',
          label: '1.1',
        },
        {
          value: '1.5',
          label: '1.5',
        },
      ],
    },
  },
  lrMargins: {
    id: 'lrMargins',
    name: 'Left & Right Margins',
    slider: {
      min: '0',
      max: '20',
      step: '2',
      marks: [
        {
          value: '10',
          label: '10mm',
        },
        {
          value: '20',
          label: '20mm',
        },
      ],
    },
  },
  tbMargins: {
    id: 'tbMargins',
    name: 'Top & Bottom Margins',
    slider: {
      min: '0',
      max: '20',
      step: '2',
      marks: [
        {
          value: '10',
          label: '10mm',
        },
        {
          value: '20',
          label: '20mm',
        },
      ],
    },
  },
};

export default sliderSections;
